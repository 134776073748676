<template>
  <div class="booking-info">
    <p class="md:text-lg">{{ props.product?.branch_name }}</p>
    <h2 class="text-lg mt-1 md:text-2xl md:mt-5">{{ props.product?.product_name }}</h2>
    <hr class="hidden md:block md:mt-5" />
    <div class="period flex justify-between items-center gap-3 mt-3 md:mt-5">
      <div class="duration text-lg text-sm md:text-base">
        <p v-if="startDateTimeString" class="start-time flex gap-3">
          <span>{{ $t('booking_time_begin') }}</span>
          <span>{{ startDateTimeString }}</span>
        </p>
        <p v-if="endDateTimeString" class="end-time flex gap-3 mt-1 md:mt-5">
          <span>{{ $t('booking_time_end') }}</span>
          <span>{{ endDateTimeString }}</span>
        </p>
      </div>
      <!-- <div class="head-count">
        2 人
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { add } from 'date-fns'

import type { UiProductInfo, UiProductSelectedData } from '@/types/product'
import type { ProductUpgradeResource } from '@/types/booking'

const { format } = await useI18nDateFns()

const props = defineProps<{
  product: UiProductInfo
  bookingItem: UiProductSelectedData[]
  upgrades: ProductUpgradeResource[]
}>()

const startDateTime = computed(() => {
  return props.bookingItem?.at?.(0)?.dateTime || ''
})

const endDateTime = computed(() => {
  return props.bookingItem?.at?.(-1)?.dateTime || ''
})

const startDateTimeString = computed(() => {
  return dateTimeStringFormatter(startDateTime.value) || ''
})

const endDateTimeString = computed(() => {
  try {
    if (props.product?.duration_text) return props.product.duration_text
    if (props.product?.checkout) {
      const [endDate, endTime] = endDateTime.value.split(' ')
      const realEndDate = isEarlierOrEqualInHourMinute(endTime, props.product.checkout)
        ? endDate
        : format(add(new Date(endDate), { days: 1 }), 'yyyy-MM-dd')
      return dateTimeStringFormatter(`${realEndDate} ${props.product.checkout}`)
    }
    if (startDateTime.value !== endDateTime.value) return dateTimeStringFormatter(endDateTime.value) || ''

    const duration = Math.max(Number(props.product?.duration), ...props.upgrades.map(u => u.duration))
    return dateTimeStringFormatter(add(new Date(startDateTime.value), { minutes: duration }))
  } catch (error) {
    return ''
  }
})

const dateTimeStringFormatter = (dateTime?: string | Date) => {
  if (!dateTime) return ''
  if (typeof dateTime === 'string') dateTime = new Date(dateTime)
  return format(dateTime, 'MM/dd HH:mm')
}
</script>

<style lang="scss" scoped></style>
